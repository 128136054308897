// We're using this file to manually mount our components, rather than relying on the
// heavyweight UJS or replacement like react-rails-ujs.
//
// In theory we could save some code by using those external helpers despite their
// size, but we're erring towards keeping a smaller footprint since this is tied
// pretty closely to security.

import { lazy, Suspense } from "react"
import { render } from "react-dom"
import { GlobalStyle } from "../components/GlobalStyleV2"
import { ErrorBoundary } from "@sentry/react"
import { IntlProvider, ThemeProvider } from "@instacart/ipp-auth-service-tools"
import "../utils/sentry"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

const components = {
  GlobalStyle,
  // Prefer lazy loading components to reduce the bundle size, when possible
  CollectEmail: lazy(() => import("../components/CollectEmail")),
  CollectPassword: lazy(() => import("../components/CollectPassword")),
  RequestPasswordReset: lazy(
    () => import("../components/RequestPasswordReset"),
  ),
  PendingPasswordReset: lazy(
    () => import("../components/PendingPasswordReset"),
  ),
  ResetPassword: lazy(() => import("../components/ResetPassword")),
  PendingMfaSetup: lazy(() => import("../components/PendingMfaSetup")),
  MfaSetup: lazy(() => import("../components/MfaSetup")),
  MfaCodeInput: lazy(() => import("../components/MfaCodeInput")),
  ChangePassword: lazy(() => import("../components/ChangePassword")),
}

// Mount the global styles
const styleRoot = document.createElement("div")
document.body.appendChild(styleRoot)
render(<GlobalStyle />, styleRoot)

// Mount all components
document.querySelectorAll("[data-react-class]").forEach((el) => {
  const componentName = el.getAttribute("data-react-class")
  const props = JSON.parse(el.getAttribute("data-react-props") || null)
  const Component = components[componentName]
  const queryClient = new QueryClient()

  // If the component isn't defined, skip it with an error, but otherwise carry on
  if (Component === undefined) {
    console.error(
      `No component named ${componentName}; did you forget to import it?`,
    )
    return
  }

  render(
    <ErrorBoundary
      fallback={<div>An error has occurred! Please contact support.</div>}
    >
      <QueryClientProvider client={queryClient}>
        <IntlProvider locale="en-US">
          <ThemeProvider>
            <Suspense fallback={<div>Loading...</div>}>
              <div id="react-app-loaded">
                <Component {...props} />
              </div>
            </Suspense>
          </ThemeProvider>
        </IntlProvider>
      </QueryClientProvider>
    </ErrorBoundary>,
    el,
  )
})
