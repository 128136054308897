import * as Sentry from "@sentry/react"

if (window.__AUTH_SERVICE_ENV__.PUBLIC_CLIENT_ENVIRONMENT === "local") {
  console.warn("Sentry is disabled in local development")
} else {
  const sha = window.__AUTH_SERVICE_ENV__.PUBLIC_CLIENT_ISC_SHA
  const branch = window.__AUTH_SERVICE_ENV__.PUBLIC_CLIENT_ISC_BRANCH
  const release = `auth-service@${sha}`

  Sentry.init({
    dsn: window.__AUTH_SERVICE_ENV__.PUBLIC_CLIENT_SENTRY_DSN,
    environment: window.__AUTH_SERVICE_ENV__.PUBLIC_CLIENT_ENVIRONMENT,
    release,
    maxBreadcrumbs: 10,
  })

  Sentry.configureScope((scope) => {
    scope.setTag("instacart-owner", "retailer-tools")
    scope.setTag("branch", branch)
    scope.setTag("service", "auth-service")
  })
}
